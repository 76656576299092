import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Grid } from "../components/grid/grid"
import Iconrow from "../components/iconrow/iconrow"
import Subfooter from "../components/footer/subfooter"

import appStyles from "../styles/app.module.scss"

import ImageStats from "../components/images/imageAppStats"
import ImageRanking from "../components/images/imageAppRanking"
import ImageChrono from "../static/tiempo.svg"
import ImageChronoMov from "../static/tiempo-movimiento.svg"
import ImageChronoHand from "../static/tiempo-aguja.svg"


const SavingPage = () => (
  <Layout>
    <SEO 
    title="Ahorra tiempo y dinero buscando parking en Barcelona | Parquick"
    description="Parquick te ayuda a ser un conductor más eficiente. Deja de perder tiempo buscando aparcamiento. Contaminando menos y sin estrés."
    />

    <Container>
      <section>
          <Grid>
            <div className={appStyles.chronoWrap + " margintop2 l-start-3 l-end-11 m-start-1 m-end-7"}>
              <div className={appStyles.chronoPlusWrap}>
                <span className={appStyles.chronoPlus1}>+</span>
                <span className={appStyles.chronoPlus2}>+</span>
                <span className={appStyles.chronoPlus3}>+</span>
              </div>
              <img src={ImageChronoHand} className={appStyles.chronoHand} alt="ahorrar al buscar parking cercano"/>
              <img src={ImageChrono} className={appStyles.chrono} alt="app para buscar aparcamiento y ahorrar"/>
              <img src={ImageChronoMov} className={appStyles.chronoMov} alt="aplicacion para encontrar aparcamiento y ganar tiempo"/>
            </div>
            <div class="l-start-4 l-end-10 m-start-2 m-end-6 center">
              <p className={appStyles.textultralarge}>16 minutos ahorrados</p>
              <p className={appStyles.noteultralarge}>de media cada vez que buscas aparcamiento.</p>
              <div className={appStyles.iconseparator}><i class="icon-arrowdown_24"></i></div>
              <p className={appStyles.textultralarge}>Menos tiempo de conducción</p>
              <div className={appStyles.iconseparator}>=</div>
            </div>
          </Grid>
          <Grid>
            <Iconrow
              class="l-start-3 l-end-11 m-start-1 m-end-7"
              icon1="icon-time_24"
              text1="Más tiempo para ti."
              icon2="icon-car_24"
              text2="Menos congestión del tráfico."
              icon3="icon-zen_24"
              text3="Menor nivel de estrés."
            />
          </Grid>

          <Grid>
            <Iconrow
              class="margintop2 l-start-3 l-end-11 m-start-1 m-end-7"
              icon1="icon-pollutionCO2_24"
              text1="CO2 y NOx no emitidos por gusto."
              icon2="icon-fuel_24"
              text2="Menos consumo de combustible."
              icon3="icon-mechanic_24"
              text3="Desgaste de vehículo evitado."
            />
          </Grid>
      </section>

      <section id="progress" class="margintop3">
        <Grid>
          <div className={appStyles.contentWrap + " l-start-1 l-end-5 m-start-1 m-end-3"}>
            <div className={appStyles.content}>
             <h2>Registra tu progreso.</h2>
              <p>Cada vez que usas Parquick, tu ahorro aumenta. Haz un seguimiento del tiempo que ganas, los costes que ahorras, y cómo reduces la emisión de gases contaminantes.</p>
            </div>
          </div>
          <div className={appStyles.iconWrap + " l-start-7 l-end-13 m-start-4 m-end-7"}>
            <i class="icon-chart_32"></i>
          </div>
            <div className={appStyles.screenWrap}>
            <div className={appStyles.screen}>
              <ImageStats alt="ahorrar tiempo y combstible con app parking barcelona"/>
            </div>
          </div>
        </Grid>

        <Grid id="ranking" class="margintop3">
          <div className={appStyles.contentWrap + " l-start-9 l-end-13 m-start-5 m-end-7"}>
            <div className={appStyles.content}>
             <h2>¿Conseguirás ser el más eficiente?</h2>
              <p>Consigue batir a otros miembros de Parquick y convertirte en el conductor más eficiente del mes. Cuanto más la uses, más puntos consigues.</p>
            </div>
          </div>
          <div className={appStyles.iconWrap + " l-start-1 l-end-7 m-start-1 m-end-4 right"}>
            <i class="icon-ranking-top_24"></i>
          </div>
            <div className={appStyles.screenWrap}>
            <div className={appStyles.screen}>
              <ImageRanking alt="aparcar en barcelona ahorrando"/>
            </div>
          </div>
        </Grid>
      </section>
    </Container>
    <Subfooter />
  </Layout>
)

export default SavingPage
